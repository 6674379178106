import { parseFile } from '@workos-inc/ui-kit';
import { ConfigurationComplete } from 'components/sso/configuration-complete';
import { Sidebar } from 'components/sso/sidebar';
import { StoreContext } from 'components/store-provider';
import {
  ConnectionType,
  OrganizationFragment,
  SamlX509CertificateValidationErrorCode,
} from 'graphql/generated';
import { ConnectionError } from 'interfaces/connection-error';
import { SSOErrors } from 'interfaces/errors';
import { UpdatableConnectionFields } from 'interfaces/step-props';
import { SupportedConnectionType } from 'interfaces/supported-connection-type';
import { OverflowPage } from 'layouts/page';
import get from 'lodash.get';
import { useRouter } from 'next/router';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { configurationTypeForConnectionType } from 'utils/connection-configuration-type';
import { EventName, URL_ERROR_MESSAGE } from 'utils/constants';
import { useFeature } from 'utils/feature-flags';
import { getOrganizationDomain } from 'utils/get-organization-domain';
import { graphql } from 'utils/graphql';
import { isURL } from 'utils/is-url';
import { unreachable } from 'utils/unreachable';
import { StepConfig, stepsConfig, stepsConfigOverrides } from './steps-config';

interface StepsProps {
  organization: OrganizationFragment;
}

export const Steps: React.FC<Readonly<StepsProps>> = ({ organization }) => {
  const {
    appName: [appName],
    domain: [, setDomain],
    connection: [connection, setConnection],
    ssoProvider: [provider],
    organization: [, setOrganization],
  } = useContext(StoreContext);
  const router = useRouter();
  const step = router.query.step
    ? parseInt(router.query.step as string, 10)
    : 1;
  const [validationErrors, setValidationErrors] = useState<SSOErrors>({});
  const [stepStartDate, setStepStartDate] = useState<Date>(new Date());
  const isOktaOINEnabled = useFeature('oktaOinAdminPortalSso');
  const isOneLoginOACEnabled = useFeature('oneLoginOacAdminPortalSso');
  const isUseGeneratedConnectionEntityIdEnabled = useFeature(
    'useGeneratedConnectionEntityId',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [connectionErrors, setConnectionErrors] = useState<SSOErrors>();
  const frameRef = useRef<HTMLDivElement>(null);
  const isCertificateMetadataInputEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  const [connectionUpdatedFields, setConnectionUpdatedFields] =
    useState<UpdatableConnectionFields>({});

  const configurationType = !!connection
    ? configurationTypeForConnectionType(
        connection.type,
        isCertificateMetadataInputEnabled,
      )
    : 'None';

  useEffect(() => {
    if (organization) {
      setDomain(getOrganizationDomain(organization));
      setOrganization(organization);
    }
  }, [organization, setDomain, setOrganization]);

  useEffect(() => {
    if (frameRef?.current) {
      frameRef.current.scrollTop = 0;
    }

    setValidationErrors({});
    setStepStartDate(new Date());
  }, [step, frameRef, provider]);

  useEffect(() => {
    setConnectionErrors(undefined);
    setConnectionUpdatedFields({
      samlIdpEntityId: connection?.saml?.idpEntityId ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const updateActiveStep = (step: number): void => {
    void router.push(`/sso/${step}`);
  };

  const parseConnectionErrors = (errors: ConnectionError[]) =>
    errors.reduce(
      (obj, e) => ({
        ...obj,
        [e.field]: {
          message: e.error,
          value: connection?.[e.field],
        },
      }),
      {},
    );

  if (!provider || !stepsConfig[provider]) {
    return null;
  }

  const filteredStepsConfig = (
    provider: SupportedConnectionType,
  ): StepConfig => {
    if (provider === ConnectionType.OktaSaml && isOktaOINEnabled) {
      return stepsConfigOverrides.OktaOIN;
    }

    if (provider === ConnectionType.OneLoginSaml && isOneLoginOACEnabled) {
      return stepsConfigOverrides.OneLoginOAC;
    }

    return stepsConfig[provider];
  };

  const { errorFields, providerLabel, steps } = filteredStepsConfig(provider);

  const StepComponent = steps[step - 1]?.Component;
  const confirmationStep = steps.length + 1;

  const onInputChange = (event: {
    target: { name: string; value: string | string[] };
  }): void => {
    const payload = { [event.target.name]: event.target.value };

    if (connection) {
      setConnection({ ...connection, ...payload });
    }
  };

  const onFileInput = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event?.target?.files) {
      const file = event?.target?.files[0];

      if (file) {
        const fileContent = await parseFile(file);

        onInputChange({
          target: { name: event.target.name, value: [fileContent] },
        });
      }
    }
  };

  const completeConnectionFromMetadataUrl = async (): Promise<void> => {
    if (connection && connection.saml_idp_metadata_url) {
      setIsLoading(true);

      try {
        const response = await graphql().UpdateConnectionFromMetadataUrl({
          input: {
            connectionId: connection.id,
            metadataUrl: connection.saml_idp_metadata_url,
          },
        });

        const result = response.data?.portal_updateConnectionFromMetadataUrl;

        switch (result?.__typename) {
          case 'Portal_ConnectionUpdatedFromMetadataUrl':
            setConnection(result.connection);
            setConnectionErrors(undefined);
            break;
          case 'ConnectionNotFound':
            await router.push('/not_found');
            break;
          case 'Portal_MetadataFetchFailed':
            setConnectionErrors({
              saml_idp_metadata_url: {
                value: connection.saml_idp_metadata_url,
                message: result.reason,
              },
            });
            break;
          case 'InvalidSamlX509Certificate':
            const { validationError } = result;

            switch (validationError) {
              case SamlX509CertificateValidationErrorCode.ExpiredCertificate:
                setConnectionErrors({
                  saml_idp_metadata_url: {
                    value: connection.saml_idp_metadata_url,
                    message:
                      'The metadata contains an expired X.509 certificate.',
                  },
                });
                break;
              case SamlX509CertificateValidationErrorCode.MalformedCertificate:
                setConnectionErrors({
                  saml_idp_metadata_url: {
                    value: connection.saml_idp_metadata_url,
                    message:
                      'The metadata contains a malformed X.509 certificate.',
                  },
                });
                break;
              default:
                unreachable(validationError);
                break;
            }

            break;
          case undefined:
            setConnectionErrors(
              parseConnectionErrors(
                get(response, [
                  'errors',
                  '0',
                  'extensions',
                  'exception',
                  'response',
                  'errors',
                ]),
              ),
            );
            break;
          default:
            unreachable(result);
        }
      } finally {
        setIsLoading(false);
      }

      updateActiveStep(confirmationStep);
    }
  };

  const completeConnectionFromMetadataXml = async (): Promise<void> => {
    if (connection && connectionUpdatedFields?.saml_idp_metadata_xml) {
      setIsLoading(true);

      try {
        const response = await graphql().UpdateConnectionFromMetadataXml({
          input: {
            connectionId: connection.id,
            metadataXml: connectionUpdatedFields.saml_idp_metadata_xml,
          },
        });

        const result = response.data?.portal_updateConnectionFromMetadataXml;

        switch (result?.__typename) {
          case 'Portal_ConnectionUpdatedFromMetadataXml':
            setConnection(result.connection);
            setConnectionErrors(undefined);
            break;
          case 'ConnectionNotFound':
            await router.push('/not_found');
            break;
          case 'Portal_MetadataParseFailed':
            setConnectionErrors({
              saml_idp_metadata_xml: {
                value: connectionUpdatedFields.saml_idp_metadata_xml,
                message: result.reason,
              },
            });
            break;
          case 'InvalidSamlX509Certificate':
            const { validationError } = result;

            switch (validationError) {
              case SamlX509CertificateValidationErrorCode.ExpiredCertificate:
                setConnectionErrors({
                  saml_idp_metadata_xml: {
                    value: connectionUpdatedFields.saml_idp_metadata_xml,
                    message:
                      'The metadata contains an expired X.509 certificate.',
                  },
                });
                break;
              case SamlX509CertificateValidationErrorCode.MalformedCertificate:
                setConnectionErrors({
                  saml_idp_metadata_xml: {
                    value: connectionUpdatedFields.saml_idp_metadata_xml,
                    message:
                      'The metadata contains a malformed X.509 certificate.',
                  },
                });
                break;
              default:
                unreachable(validationError);
                break;
            }

            break;
          case undefined:
            setConnectionErrors(
              parseConnectionErrors(
                get(response, [
                  'errors',
                  '0',
                  'extensions',
                  'exception',
                  'response',
                  'errors',
                ]),
              ),
            );
            break;
          default:
            unreachable(result);
        }
      } finally {
        setIsLoading(false);
      }

      updateActiveStep(confirmationStep);
    }
  };

  const completeConnection = async (): Promise<void> => {
    if (configurationType === 'MetadataUrl') {
      await completeConnectionFromMetadataUrl();
      return;
    }

    if (configurationType === 'MetadataXml') {
      await completeConnectionFromMetadataXml();
      return;
    }

    if (connection) {
      setIsLoading(true);

      try {
        const response = await graphql().UpdateConnection({
          input: {
            connectionId: connection.id,
            name: connection.name,
            oidc: {
              clientId: connection.oidc_client_id,
              clientSecret: connection.oidc_client_secret,
              discoveryEndpoint: connection.oidc_discovery_endpoint,
            },
            saml: {
              idpEntityId: isUseGeneratedConnectionEntityIdEnabled
                ? connectionUpdatedFields.samlIdpEntityId
                : connection.saml_entity_id,
              idpUrl: connection.saml_idp_url,
              relyingPartyPrivateKey: connection.saml_relying_party_private_key,
              x509Certs: connection.saml_x509_certs,
            },
          },
        });

        const result = response.data?.portal_updateConnection;

        switch (result?.__typename) {
          case 'Portal_ConnectionUpdated':
            setConnection(result.connection);
            setConnectionErrors(undefined);
            break;
          case 'ConnectionNotFound':
            await router.push('/not_found');
            break;
          case 'VerifyConnectionFailed':
            setConnectionErrors(
              parseConnectionErrors(
                result.connectionErrors as ConnectionError[],
              ),
            );
            break;
          case 'InvalidSamlX509Certificate':
            setConnectionErrors({
              saml_x509_certs: {
                value: connection.saml_x509_certs?.[0] ?? '',
                message:
                  'Invalid SAML X509 Certificate. Did you upload the correct file?',
              },
            });
            break;
          case undefined:
            setConnectionErrors(
              parseConnectionErrors(
                get(response, [
                  'errors',
                  '0',
                  'extensions',
                  'exception',
                  'response',
                  'errors',
                ]),
              ),
            );
            break;
          default:
            unreachable(result);
        }
      } finally {
        setIsLoading(false);
      }

      updateActiveStep(confirmationStep);
    }
  };

  const onNextStep = async (): Promise<void> => {
    const newValidationErrors: SSOErrors = {};

    if (
      connection?.oidc_discovery_endpoint &&
      !isURL(connection?.oidc_discovery_endpoint)
    ) {
      newValidationErrors.oidc_discovery_endpoint = {
        message: URL_ERROR_MESSAGE,
        value: connection?.oidc_discovery_endpoint,
      };
    }

    if (connection?.saml_idp_url && !isURL(connection?.saml_idp_url)) {
      newValidationErrors.saml_idp_url = {
        message: URL_ERROR_MESSAGE,
        value: connection?.saml_idp_url,
      };
    }

    setValidationErrors(newValidationErrors);

    if (
      newValidationErrors.oidc_discovery_endpoint ||
      newValidationErrors.saml_idp_url
    ) {
      return;
    }

    if (connectionErrors) {
      updateActiveStep(confirmationStep);
      return;
    }

    const nextStep = step + 1;

    if (nextStep === confirmationStep) {
      await completeConnection();
      return;
    }

    updateActiveStep(nextStep);

    void graphql().TrackEvent({
      event_name: EventName.SSOStepCompleted,
      attributes: {
        step_name: stepsConfig[provider].steps[step].name,
        step_number: step,
        connection_type: provider,
        duration: new Date().getTime() - stepStartDate.getTime(),
      },
    });
  };

  return (
    <OverflowPage pageRef={frameRef}>
      <StyledSection>
        <Sidebar
          steps={[...steps, { name: 'Confirmation' }]}
          stepsWithError={
            connectionErrors &&
            Object.keys(connectionErrors).map(
              (field) => errorFields[field]?.step,
            )
          }
        />

        {StepComponent && (
          <StepComponent
            appName={appName}
            connection={connection}
            connectionUpdatedFields={connectionUpdatedFields}
            errors={connectionErrors}
            isLoading={isLoading}
            onFileInput={onFileInput}
            onInputChange={onInputChange}
            onNextStep={onNextStep}
            setConnectionUpdatedFields={setConnectionUpdatedFields}
            validationErrors={validationErrors}
          />
        )}

        {connection && step === confirmationStep && (
          <ConfigurationComplete
            completeConnection={completeConnection}
            connectionUpdatedFields={connectionUpdatedFields}
            errorFields={errorFields}
            errors={connectionErrors}
            isLoading={isLoading}
            onFileInput={onFileInput}
            onInputChange={onInputChange}
            providerLabel={providerLabel}
            setConnectionUpdatedFields={setConnectionUpdatedFields}
          />
        )}
      </StyledSection>
    </OverflowPage>
  );
};

const StyledSection = styled.section`
  display: grid;
  grid-template-columns: 300px 680px;
  grid-gap: 90px;
`;
